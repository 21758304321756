
.wrapper {
  display: flex;
}
.specifications{
  display: flex;
  flex-wrap: wrap;
  justify-content:space-around;
}
.mobilefoot{
display:none;
}
.navbarimage{
  height:350px;
  margin-bottom:30px;
}

.right-column {
  width: 293px;
  position: fixed;
  top: 180px;
  right: 45px;
}

.fixed {
  padding: 20px;
  height: 500px;
  position: fixed;
  z-index: 0;
  width: 302px;
  top: 10px;
}

.content {
  padding: 35px;
width: 900px;
}

h2 {
  margin-top: 0;
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
  padding: 55px;
}

.heading {
  border-bottom: solid 1px #e1dada;
  padding: 17px;
  font-size: 18.5px;
  font-weight: 600;
  height: 55px;
  color: black;
  opacity: 0.99;

}


.box {
  display: flex;
  flex-direction: column;
  width: calc(26.3% - 5px);
  margin: 10px;
  padding: 20px;
  /* box-shadow: -1px 5px 6px #747373; */
  object-fit: contain;
  font-size: 12px;
  align-items: center;
  text-transform: uppercase;
  border-radius: 50px;
  /* background: linear-gradient(145deg, #efecec, #c9c7c7); */
  box-shadow: 28px 28px 56px #bebcbc, -28px -28px 56px #fffefe;
  height: 130px;
  margin-right: 45px;
}

.icon {
  display: flex;
  justify-content: left;
  align-items: left;
  font-size: 24px;
  margin-bottom: 10px;
}

.text {
  display: flex;
  flex-direction: column;
}

.title {
  font-weight: bold;
  margin-bottom: 5px;
  text-align: center;
  color: rgb(153, 153, 153);

}

.description {
  font-size: 12px;
  color: #000;
  text-align: center;
  opacity: 0.99;
  font-weight: 500;

}

.wrapper {
  display: flex;
}

.button1 {
  width: 130px;
  margin: 15px;
  text-align: center;
  line-height: 50px;
  cursor: pointer;
  background: #b48811;
  border-radius: 16px;
  position: relative;
  /* border: 5px solid white; */
  box-shadow: 4px 4px 10px #00000094 !important;
  color:black;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 700;
  font-family: sans-serif;
  letter-spacing: 1px;

}
.react-tabs__tab--selected {
  background: #fff!important;
  /* border-color: #aaa; */
  color: #000000!important;
  border-radius: 5px 5px 0 0!important;
  border-bottom: 5px solid #b48811!important;
  box-shadow: -2px -1px 2px 2px #bbbab7!important;
  font-size: 13px!important;
  font-weight: 554!important;
  text-transform: uppercase !important;
}

.react-tabs--selected  {
  display: inline-block;
  border: 1px solid #b48811; 

  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 6px 12px;
  cursor: pointer;
}

.react-tabs__tab-list {
  border-bottom: 1px solid #aaaaaa2e !important;
  margin: 0 0 10px;
  padding: 0;
}
.text{
  display: block;
  width: 100%;
  text-align: justify;
}
  
.read-or-hide{
  color: rgb(30, 58, 219);
  cursor: pointer;
}


.navlink {
  display: inline-block;
  color: #fff;
  text-align: center;
  padding: 14px;
  text-decoration: none;
  font-size: 17px;
}

.navlink:hover {
  background-color: #ddd;
  color: black;
}
.imgposition{
  position: relative;
  top: 212px;

}
.sidebarheight{
  height:208px
}

@media (max-width:576px) {
  .mobilefoot{
    display:block;
    position: relative;
    z-index: 100;
  }
  .navbarimage{
    margin-top: 90px;
  }
  .navbar {
    display: none;
  }
  .specifications{
    display: flex;
    flex-wrap: wrap;
   
  }
 
}
.scrollable-menu {
  background-color: #fff;
  border-top: 1px solid #e9e9e9;
  padding: 0 calc((100% - 1064px) / 2);
  box-shadow: 0 4px 6px 0 rgba(0,0,0,0.12);
  position: fixed;
  top: 0;
  z-index: 3;
  display: flex;
  height: 50px;
  width: 763px;
  flex-direction: column-reverse;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;

}
.css-1m5ci4z {
  display: flex;
  align-items: center;
  background: rgb(206 206 217);
  border-radius: 8px;
  padding: 5px;
  width: 120px;
  height: 40px;
  justify-content: center;
  cursor: pointer;
  border: none;
  float: right;
  margin-right: 20px;
  margin-top:-20px;
  color: #b48811;
}
.css-1m5ci4z111 {
  display: flex;
  align-items: center;
  background: rgb(206 206 217);
  border-radius: 8px;
  padding: 5px;
  width: 120px;
  height: 40px;
  justify-content: center;
  cursor: pointer;
  border: none;
  float: right;
  margin-right: 20px;
  margin-top:-65px;
  color: #b48811;
}
.css-erp5gv {
  margin: 16px 1px 10px 1px;
  line-height: normal;
  font-weight: 500;
  font-size: 16px;
}
.css-12145tgb{
  display: flex;
    font-size: 15px;
    font-weight: 400;
    opacity: 0.7;
    padding: 0px;
}

.csss-rewqt{
  display: block;
  height: 110px;
  border-bottom: 1px solid #eeee;
}

.row > .column {
  padding: 0 8px;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

/* Create four equal columns that floats next to eachother */
.column {
  float: left;
  width: 25%;
}

/* The Modal (background) */

/* Modal Content */


/* The Close Button */


.close:hover,
.close:focus {
  color: #999;
  text-decoration: none;
  cursor: pointer;
}

/* Hide the slides by default */
.mySlides {
  display: none;
}

/* Next & previous buttons */
.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -50px;
  color: white;
  font-weight: bold;
  font-size: 20px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
  -webkit-user-select: none;
}
.iconbox{
  box-shadow: 28px 28px 56px #bebcbc, -28px -28px 56px #fffefe;
  border-radius: 35px;
  margin:10px
}
/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

/* Caption text */
.caption-container {
  text-align: center;
  background-color: black;
  padding: 2px 16px;
  color: white;
}

img.demo {
  opacity: 0.6;
}

.active,
.demo:hover {
  opacity: 1;
}

img.hover-shadow {
  transition: 0.3s;
}

.hover-shadow:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.css-1hidc9c {
  text-shadow: 0.5px 0 0 currentColor;
  font-size: 24px;
}

.fontsizeslider{
  font-size: 16px;
}

.home-banner { 
    position: relative; 
    background-color: #dcdcdc; 
    height: 100vh; 
    background-size: cover; 
    background-position: center center; 
    padding-top: 70px;
} 

*,:after,:before { 
    box-sizing: border-box;
} 

.home-banner:after { 
    position: absolute; 
    content: ""; 
    height: 100%; 
    width: 100%; 
    background: linear-gradient(to top,rgb(0 0 0 / 60%) 20%,rgb(0 0 0 / 0%) 40%,rgb(0 0 0 / 20%) 60%,rgb(0 0 0 / 70%) 100%); 
    bottom: 0; 
    left: 0; 
    z-index: 0;
} 

.home-banner-img { 
    display: none;
} 

img { 
    vertical-align: middle;
} 

.img-fluid { 
    max-width: 100%; 
    height: auto;
} 

.project-configurations { 
    text-shadow: 0px 1px 2px #000; 
    padding-left: 0px;
} 

.container { 
    width: 100%; 
    padding-right: var(--bs-gutter-x,.75rem); 
    padding-left: var(--bs-gutter-x,.75rem); 
    margin-right: auto; 
    margin-left: auto;
} 

@media (min-width: 576px){ 
  .container { 
    max-width: 540px;
  } 
}     

@media (min-width: 768px){ 
  .container { 
    max-width: 720px;
  } 
}     

@media (min-width: 992px){ 
  .container { 
    max-width: 960px;
  } 
}     

@media (min-width: 1200px){ 
  .container { 
    max-width: 1140px;
  } 
}     

.container { 
    max-width: 1280px;
} 

.banner-cnt { 
    position: absolute; 
    left: 0; 
    width: 100%; 
    bottom: 15px; 
    z-index: 1;
} 

p { 
    margin-top: 0; 
    margin-bottom: 1rem;
} 

.project-configurations p  { 
    font-size: 16px; 
    color: #fff; 
    font-family: 'poppinsbold';
} 

.banner-configurations-site-visit { 
    display: flex; 
    align-items: center;
} 

.project-site-visit { 
    display: flex; 
    align-items: center;
} 

h1 { 
    margin-top:  0; 
    margin-bottom:  .5rem; 
    font-weight:  500; 
    line-height:  1.2;
    font-size: calc(1.375rem+1.5vw);
} 

@media (min-width: 1200px){ 
  h1 { 
    font-size: 2.5rem;
  } 
}     

.project-configurations h1  { 
    color: #fff; 
    font-size: 42px; 
    font-family: 'poppinsbold';
} 

.banner-configurations { 
    display: flex;
} 

.configuration-item { 
    padding-right: 20px; 
    position: relative;
} 

.configurations-size { 
    position: relative;
} 

.configurations-size:after { 
    content: ""; 
    background-color: gainsboro; 
    width: 1px; 
    height: 100%; 
    position: absolute; 
    right: 10px; 
    bottom: 0;
} 

a { 
    color:  #666666; 
    text-decoration:  none;
    font-weight: bold; 
} 

.btn { 
    display: inline-block; 
    font-weight: 400; 
    line-height: 1.5; 
    color:#fff;; 
    text-align: center; 
    text-decoration: none; 
    vertical-align: middle; 
    cursor: pointer; 
    -webkit-user-select: none; 
    -moz-user-select: none; 
    user-select: none; 
    background-color: transparent; 
    border: 1px solid transparent; 
    padding: .375rem .75rem; 
    font-size: 1rem; 
    border-radius: .25rem; 
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
} 

.project-site-visit .btn  { 
    background-color: #c08831; 
    border-radius: 0; 
    color: #fff; 
    font-size: 14px; 
    letter-spacing: 2px; 
    font-weight: 600; 
    text-transform: uppercase; 
    border: 2px solid #c08831; 
    padding: 10px 25px;
} 

a:hover { 
    color:  #666666; 
    text-decoration: none;
} 

.btn:hover { 
    color: #212529;
} 

h4 { 
    margin-top:  0; 
    margin-bottom:  .5rem; 
    font-weight:  500; 
    line-height:  1.2;
    font-size: calc(1.275rem+.3vw);
} 

@media (min-width: 1200px){ 
  h4 { 
    font-size: 1.5rem;
  } 
}     

.configuration-item h4  { 
    color: #c08831; 
    font-size: 20px; 
    font-family: 'poppinssemibold'; 
    text-transform: uppercase;
    text-align: center;
} 

.rts___btn {
    cursor: pointer;
    background-color: transparent!important;
    /* border-radius: 40px; */
    border: transparent!important;
    /* transition: 0.3s all linear; */
}


.rts___tabs {
  display: flex;
  overflow: auto;
  padding: 15px ;
  height: 75px!important;
 
}




.right-sti { 
    position:  sticky; 
    position:  sticky; 
    top:  23px!important;
} 

.col-md-4 { 
    position: relative; 
    width: 100%; 
    padding-right: 15px; 
    padding-left: 15px;
} 

@media (min-width: 768px){ 
  .col-md-4 { 
    -ms-flex: 0 0 33.333333%; 
    flex: 0 0 33.333333%; 
    max-width: 33.333333%;
  } 
}     

.row { 
    display: -ms-flexbox; 
    display: flex; 
    -ms-flex-wrap: wrap; 
    flex-wrap: wrap; 
    margin-right: -15px; 
    margin-left: -15px;
} 

.container { 
    max-width:  1300px!important;
    width: 100%; 
    padding-right: 15px; 
    padding-left: 15px; 
    margin-right: auto; 
    margin-left: auto;
} 

@media (min-width: 576px){ 
  .container { 
    max-width: 540px;
  } 
}     

@media (min-width: 768px){ 
  .container { 
    max-width: 720px;
  } 
}     

@media (min-width: 992px){ 
  .container { 
    max-width: 960px;
  } 
}     

@media (min-width: 1200px){ 
  .container { 
    max-width: 1140px;
  } 
}     

.details-content { 
    background-color: #fcfdfe; 
    padding: 30px;
} 

section { 
    display: block;
} 





.brochure  { 
  background:linear-gradient(#fea,#dc8 49%,#a95 51%,#dc8);
  padding: 2px;
  box-shadow: 2px 2px 5px 0 #00000014;
  border-radius: 6px!important;
  background-size: 160px;
  z-index: 0;
  position: relative;
    
} 

*,:after,:before { 
    box-sizing: border-box;
} 

:selection { 
    color: #fff; 
    background-color: #3297FD;
} 

p { 
    margin-top: 0; 
    margin-bottom: 1rem;
    padding: 10px;
    text-align: justify;
} 

.download_pdf_p { 
    font-size: 20px; 
    font-weight: 700; 
    color: #fff; 
    text-align: center;
} 

main { 
    display: block;
} 

#btnmain { 
    position: relative; 
    display: flex; 
    justify-content: center; 
    align-items: center; 
    width: 100%;
} 

.btnsection { 
    position: relative; 
    display: flex; 
    justify-content: center; 
    text-align: center;
} 

button { 
    border-radius:     0;
    margin:    0; 
    font-family:    inherit; 
    font-size:    inherit; 
    line-height:    inherit;
    overflow:   visible;
    text-transform:  none;
    -webkit-appearance: button;
} 

button:not(:disabled) { 
    cursor: pointer;
} 

.btnsection button  { 
  position: relative;
    top: -12px;
    width: 300px;
    height: 40px;
    border: none;
    outline: none;
    border-radius: 8px;
    font-family: sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 18px;
    line-height: 1.5;
    letter-spacing: 1px;
    color: white;
    background-color: var(--theme-color);
    box-shadow: 0 5px 10px -5px var(--theme-color);
    cursor: pointer;
    transition: 353ms;
} 

.btnsection button:hover  { 
    transform: scale(1.02); 
    box-shadow: 0 15px 20px -15px var(--theme-color);
} 

.bar-container { 
    position: absolute; 
    top: 45px; 
    width: 50px; 
    height: 8px; 
    opacity: 0; 
    border-radius: 8px; 
    overflow: hidden; 
    background-color: #ecebec; 
    transition: 300ms;
} 

.fas { 
    display:  var(--fa-display,inline-block); 
    -moz-osx-font-smoothing:  grayscale; 
    -webkit-font-smoothing:  antialiased; 
    font-style:  normal; 
    font-variant:  normal; 
    line-height:  1; 
    text-rendering:  auto;
    font-family: "Font Awesome 6 Free";
} 

.fa-lg { 
    font-size: 1.25em; 
    line-height: .05em; 
    vertical-align: -.075em;
} 

.repeat-btn { 
    position: absolute; 
    visibility: hidden; 
    top: 30%; 
    vertical-align: middle; 
    color: #98c8fe; 
    cursor: pointer; 
    transition: 300ms; 
    right: -20%;
} 

.fas { 
    -moz-osx-font-smoothing: grayscale; 
    -webkit-font-smoothing: antialiased; 
    display: inline-block; 
    font-style: normal; 
    font-variant: normal; 
    text-rendering: auto; 
    line-height: 1;
} 

.fa-lg { 
    font-size: 1.33333em; 
    line-height: .75em; 
    vertical-align: -.0667em;
} 

.fas { 
    font-family:  "Font Awesome 5 Free";
    font-weight: 900;
} 

.fa-lg { 
    font-size: 1.33333333em; 
    line-height: .75em; 
    vertical-align: -15%;
} 

.fa-redo-alt:before,.fa-rotate-forward:before,.fa-rotate-right:before { 
    content: "\f2f9";
} 

.fa-redo-alt:before { 
    content: "\f2f9";
} 

.fa-download:before { 
    content: "\f019";
} 

.progress-bar { 
    display:  flex; 
    display:  -ms-flexbox; 
    -ms-flex-direction:  column; 
    flex-direction:  column; 
    -ms-flex-pack:  center; 
    justify-content:  center; 
    overflow:  hidden; 
    color:  #fff; 
    text-align:  center; 
    white-space:  nowrap; 
    background-color:  #018f40; 
    transition:  5000ms cubic-bezier(0.85, 0, 0.15, 1);
    position: absolute; 
    top: 0; 
    left: 0; 
    width: 0; 
    height: 8px; 
    border-radius: 8px; 
} 


.wrappercontainer{
  padding: 8px;
  margin: 25px;
  position: relative;
  z-index: 0;
  border-radius: 50px;
  /* background: #e0e0e0; */
  box-shadow: 20px 20px 60px #bebebe, -20px -20px 60px #ffffff;
  width: 98%;
}
.iconheight{
  height:50px;
}

.css-1tzoggr {
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  color: black;
}

.css-10tc81v {
  display: flex;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.2px;
  color: rgb(0, 0, 0);
  padding-right: 6px;
}




.row { 
    display: flex; 
    flex-wrap: wrap; 
    margin-right: -15px; 
    margin-left: -15px;
} 

.justify-content-center { 
    justify-content: center!important;
} 

.container { 
    width: 100%; 
    padding-right: 15px; 
    padding-left: 15px; 
    margin-right: auto; 
    margin-left: auto;
} 

@media (min-width: 440px){ 
  .container { 
    max-width: 540px;
  } 
}     

@media (min-width: 768px){ 
  .container { 
    max-width: 720px;
  } 
}     

@media (min-width: 992px){ 
  .container { 
    max-width: 960px;
  } 
}     

@media (min-width: 1200px){ 
  .container { 
    max-width: 1140px;
  } 
}     

.bg-primary { 
    background-color: #e6e7ee!important;
} 

.pt-0 { 
    padding-top: 0!important;
} 

.section { 
    position: relative; 
    padding-top: 3rem; 
    padding-bottom: 3rem;
} 

@media (min-width: 440px){ 
  .section { 
    position: relative; 
    padding-top: 6rem; 
    padding-bottom: 6rem;
  } 

  .section-lg { 
    padding-top: 8rem; 
    padding-bottom: 8rem;
  } 
}     

.widthcontainer{
  width:75%
}



@media (min-width: 768px){ 
  .col-md-10 { 
    flex: 0 0 83.33333%; 
    max-width: 83.33333%;
  } 
}     

@media (min-width: 992px){ 
  .col-lg-8 { 
    flex: 0 0 66.66667%; 
    max-width: 66.66667%;
  } 
}     

*,:after,:before { 
    box-sizing: border-box;
} 

.rounded { 
    border-radius: .55rem!important;
} 

[class*="shadow"] { 
    transition: all .2s ease;
} 

.shadow-soft { 
    box-shadow: 6px 6px 12px #b8b9be,-6px -6px 12px #fff!important;
} 

.card { 
    position: relative; 
    display: flex; 
    flex-direction: column; 
    min-width: 0; 
    word-wrap: break-word;
    background-clip: border-box; 
    border: .0625rem solid rgba(243,247,250,.05); 
    border-radius: .55rem;
} 

.card-body { 
    flex: 1 1 auto; 
    padding: 1.5rem;
} 

.border-light { 
    border-color: #d1d9e6!important;
} 

.mb-0 { 
    margin-bottom: 0!important;
} 

.card { 
    position: relative;
} 

.accordion > .card  { 
    overflow: hidden;
} 

.accordion .card  { 
    margin-bottom: 0.1rem;
} 

.accordion > .card:first-of-type  { 
    border-bottom: 0; 
    border-bottom-right-radius: 0; 
    border-bottom-left-radius: 0;
} 

.accordion > .card:not(:first-of-type):not(:last-of-type)  { 
    border-bottom: 0; 
    border-radius: 0;
} 

.accordion > .card:last-of-type  { 
    border-top-left-radius: 0; 
    border-top-right-radius: 0;
} 

a { 
    color:  #31344b; 
    text-decoration:  none; 
    background-color:  transparent;
    font-weight: 550; 
    transition: all .2s ease;
    font-size: 12px;
} 

.accordion-panel-header { 
    display: flex; 
    -moz-justify-content: space-between; 
    -ms-justify-content: space-between; 
    justify-content: space-between; 
    -ms-flex-pack: space-between; 
    -moz-align-items: center; 
    -ms-align-items: center; 
    align-items: center; 
    -webkit-user-select: none; 
    -ms-user-select: none; 
    user-select: none;
} 

a:hover { 
    color:  #262833; 
    text-decoration:  none;
    cursor: pointer;
} 

.accordion-panel-header:hover { 
    cursor: pointer;
} 

[data-toggle]:hover { 
    cursor: pointer;
} 

.collapse:not(.show) { 
    display: none;
} 

.h6 { 
    margin-bottom:  .5rem; 
    font-family:  inherit; 
    font-weight:  400; 
    line-height:  1.3; 
    color:  #31344b;
    font-size: 1rem;
} 

.font-weight-bold { 
    font-weight: 600!important;
} 

.h6 { 
    font-family: "Nunito Sans",sans-serif; 
    font-weight: 400;
} 

.accordion-panel-header:hover > *  { 
    color: #262833;
} 

.icon { 
    text-align: center; 
    display: inline-flex; 
    -moz-align-items: center; 
    -ms-align-items: center; 
    align-items: center; 
    -moz-justify-content: center; 
    -ms-justify-content: center; 
    justify-content: center; 
    -ms-flex-pack: center;
} 

.accordion-panel-header .icon  { 
    color: #31344b; 
    transition: all .2s ease;
} 

.pt-3 { 
    padding-top: 1rem!important;
} 

.fas { 
    display:   inline-block; 
    -moz-osx-font-smoothing:   grayscale; 
    -webkit-font-smoothing:   antialiased; 
    font-style:   normal; 
    font-variant:   normal; 
    text-rendering:   auto; 
    line-height:   1;
    font-family:  "Font Awesome 5 Free";
    font-weight: 900;
} 

.icon span  { 
    font-size: 2.25rem;
} 

.accordion-panel-header .icon span  { 
    font-size: .875rem;
} 

.fa-plus:before { 
    content: "\f067";
} 

.accordion-panel-header:hover > * span  { 
    fill: #262833;
} 

p { 
  margin-top: 0;
  /* margin-bottom: 1rem; */
  font-family: Times new Roman;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
} 


.faqquestion{
  margin-bottom: 1rem;
  font-family: "Nunito Sans",sans-serif;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.6;
  padding: 12px;

}
.active{
  color: #b48811;
   
    font-size: 12px;
    margin: 5px;
}
.rts___nav___btn___container {
  display: none!important;
}
.rts___tab {
  padding: 15px 10px 0 15px!important;
    white-space: nowrap;
    vertical-align: middle;
    text-align: center;
    margin: 0 5px;
    font-size: 12px;
    text-transform: uppercase;
    color:white
}
.rts___tabs {
  display: flex;
  overflow: auto;
  padding: 20px 0!important;
}
.propertywise{
  display: flex;
  background: #fff;
  /* box-shadow: inset 26px 26px 53px #5e5a5a, inset -26px -26px 53px #fdfdfd; */
  box-shadow: 28px 28px 56px #bebcbc, -28px -28px 56px #fffefe;
  height: 50px;
  line-height: 46px;
  font-size: 16px;
  text-align: left;
  margin: 5px;
  text-transform: uppercase;
  padding: 8px;
  border-radius: 5px;

}
.propertywise:hover{
  background:linear-gradient(191deg,#fea,#dc8 95%,#a95 -21%,#dc8)
}
.iconpropertywise{
  width: 42px;
  margin-left: 3px;
  box-shadow: -2px -2px 5px white, 3px 3px 5px rgba(0, 0, 0, 0.1);
  margin-right: 2px;
  background: #b48811;
  /* border-radius: 27px; */
  
}
.buttonbottom{
  bottom: -35px;
}
.propertywiseleft{
  width: 200px;
  font-size: 12px;
 font-weight: 600;
 line-height: 40px;
}
.propertywiseright{
width: 200px;
font-size: 12px;
text-transform: none;
z-index: 2;
text-align: center;
}
.collapsed{
padding-left: 5%;
padding-top: 2%;
}
.expanded{
  padding-left: 8%;
  padding-top: 2%;
  }
  .showmore{
    position: relative;
    right: -133%;
    top: -90px;
    /* border: 1px solid black; */
    width: 17%;
    height: 54px;
    border-radius: 10px;
    font-size: 12px;
  }

  .showless{
    position: relative;
    right: -215px;
    bottom: 6px;
    /* border: 1px solid black; */
    width: 17%;
    height: 54px;
    border-radius: 10px;
    font-size: 12px;


  }
  .floorplan{
    display: flex;
    background: #fff;
    /* box-shadow: inset 26px 26px 53px #5e5a5a, inset -26px -26px 53px #fdfdfd; */
    box-shadow: 28px 28px 56px #bebcbc, -28px -28px 56px #fffefe;
    height: 40px;
    line-height: 33px;
    font-size: 16px;
    padding: 8px;
    /* border-radius: 0px 20px 0px; */
    width: 826px;
  } 
  .sidebar{
    height: 451px;
     position: fixed;
     width: 319px;
    right: 0;
     top: 171 px
  }
  .maincontainer{
  top:3300px;
  position: relative;
  z-Index: 0 ;
  }
  .whatsappbutton{
  width: 18%;
  position: relative;
  bottom: 22px;
  font-size: 12px;
  }
  .schedulevisitbutton{
  width: 22%;
  position: relative;
  bottom: 22px;
  right: 221px;
  font-size:12px;

  }
  .iconsize{
  width:100px;
  padding:5px
  }
  .floorplandescription{
  width:30%;
  display:block;
  }
  .inputmodal{
    height:40px;
    width:90%;
    border:1px solid #b48811;
    margin:5px;
    background:white;
  }
.leftmodal{
  width:50%;

}
.rightmodal{
  width: 50%;
  padding:20px;
}
.input1{
position:absolute;
top:30%;
right: 12%;
}
.input2{
  position:absolute;
  top:45%;
  right: 12%;
}
.input3{
  height: 35px;
  width: 100px;
  background: rgb(22, 26, 45);
  color: white;
  font-size: 16px;
  border-radius: 5px;
  /* margin-left: 90px; */
  padding: 2px;
  /* margin-top: 30px; */
  border: none;
  position: absolute;
  top: 65%;
  right: 19%;
}
  @media only screen and (max-width: 440px) {
    input[type="text"],
input[type="email"],
input[type="password"],
input[type="submit"],
textarea {
 
  border: 1px solid;
  border-color: #b48811;
  height: 45px;
  -webkit-box-shadow: none;
  box-shadow: none;
  /* padding-left: 20px; */
  font-size: 16px;
  color: var(--ltn__paragraph-color);
  width: 250px;
  /* padding: 10px
px
; */
  /* margin-bottom: 30px; */
  border-radius: 30px;
  /* padding-right: 59px; */
  letter-spacing: 0px!important;
  color: var(--ltn__paragraph-color);
  font-size: 14px;
}
.input1 {
  position: absolute;
  top: 30%;
  right: 18%;
}
.input2{
  position:absolute;
  top:45%;
  right: 18%;
}
.input3 {
  height: 35px;
  width: 100px;
  background: rgb(22, 26, 45);
  color: white;
  font-size: 16px;
  border-radius: 5px;
  /* margin-left: 90px
px
; */
  padding: 2px;
  /* margin-top: 30px; */
  border: none;
  position: absolute;
  top: 65%;
  right: 40%;
}

    .leftmodal{
      display:none
    
    }
    .rightmodal{
      width: 100%;
      padding:20px;
      height:350px;
    }
    .sidebar{
display: none;
    }
    .wrappercontainer{
      width: 100%;
      box-shadow: 20px 20px 60px #bebebe, -20px -20px 60px #ffffff;
    }
 
      .content {
      width: 100%;
      }
      .mobile{
        display:none;
      }
      .wrappercontainer-mobile{
  padding: 8px;
  margin-top: 25px;
  position: relative;
  z-index: 0;
  border-radius: 50px;
  /* background: #e0e0e0; */
  box-shadow: 20px 20px 60px #bebebe, -20px -20px 60px #ffffff;
      }
      .flex-container-mobile{
        display: flex;
        flex-wrap: wrap;
        padding: 5px;

      }
      .box-mobile{
        display: flex;
        flex-direction: column;
        width: calc(42% - 5px);
        margin: 10px;
        padding: 10px;
        object-fit: contain;
        font-size: 12px;
        align-items: center;
        text-transform: uppercase;
        border-radius: 50px;
        box-shadow: 28px 28px 56px #bebcbc, -28px -28px 56px #fffefe;
        height: 130px;
        
      }
  }
.fixedsidebar{
  position:fixed;
  right:0;
  width:23%;
  bottom:1px;

}
.awssld__bullets {
  position: absolute;
  bottom: 0!important;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.awssld__controls button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px!important;
  height: var(--control-button-height);
  position: absolute;
  z-index: 10;
  top: calc(50% - (0.5 * var(--control-button-height)));
  border: none;
  background-color: #fff!important;
  color: #000000!important;
  cursor: pointer;
}
.awssld__content {
  background-color: #fff!important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.golden{
  background:linear-gradient(191deg,#fea,#dc8 95%,#a95 -21%,#dc8)
}
.specifications{
display: flex;
 justify-content:space-evenly;
 flex-wrap:wrap;
}
.schedulebutton{
  margin-Left: 5px;
 font-Size: 14px;
  color: black;
}
.floorplanimg{
  text-align:center;
  height: 280px;
  width: 50%,;
  padding: 10px
}
.typetablist{
    font-size: 16px;
    margin: 20px;
    color: #000;
    border-radius: 10px;
}
.tablistsizes{
  font-size: 14px;
  margin: 20px;
 
}
.btnslider {
  margin-top: 20%;
  display:flex;
  justify-content: center;
}
.mobheader{
  display:none;
}
.footwidth{
  width:75%
}
.navbarmobile{
  display:none;
}
.propertywise2 {
  background: #fff;
  border-radius:10px;
  box-shadow: 28px 28px 56px #bebcbc, -28px -28px 56px #fffefe;
  display: flex;
  height: 50px;
  line-height: 46px;
  margin: 11px;
  padding: 8px;
  text-align: left;
  text-transform: uppercase;
  width: 90%;
}
.floorplancontainer{
  display:flex;
  flex-Wrap:wrap;
  justify-Content:space-around;
}

.frDlAt div {
  width: 24px!important;
  height: 1px !important;
  background-color: rgb(255, 255, 255);
  border-radius: -1px;
  transform-origin: 1px center;
  transition: all 0.3s linear 0s;
}
.frDlAt {
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: 15px;
  left: 12px!important;
  z-index: 20;
  display: none;
}
.navbarmobile{
  display:none;
}


.specifications{
  display: flex;
  flex-wrap:wrap;
}
.showless {
  position: relative;
  right: -311px;
  bottom: 6px;
  /* border: 1px solid black; */
  width: 17%;
  height: 28px;
  border-radius: 10px;
  font-size: 12px;
  top: -75px;
}
.showmore {
  position: relative;
    right: -538px;
    /* top: -90px; */
    /* border: 1px solid black; */
    width: 17%;
    height: 28px;
    border-radius: 10px;
    font-size: 12px;
    top: -89px;
}
.description {
  font-size: 11px;
  color: #000;
  text-align: center;
  opacity: 0.99;
  font-weight: 500;
}

.schedulebutton{
  margin-left: 15px;
  font-size: 11px;
  color: black;
}
.iconsize{
  width:100px;
  padding:5px
  }

  .floorplanimg{
    text-align:center;
    height: 280px;
    width: 100%,;
    padding: 10px
  }
  .floorplandescription{
    width:30%;
    display:block;
    }

    .typetablist{
      font-size: 11px;
      margin: 5px;
      color: #000;
      border-radius: 10px;
  }
  .tablistsizes{
    font-size: 12px;
    margin: 5px;
   
  }
  .imgposition {
    position: relative;
    top: 212px;
}



.home-banner:after {

    position: absolute;
    /* content: ""; */
    /* height: 100%; */
    width: 100%;
    background: white;
    bottom: 0;
    left: 0;
    z-index: 0;

}

.mobheader{
  display: flex;
    background: #000000;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 99;
    height: 90px;

}

.ltn__social-media{
  text-align: center;
}
.divheight {
  height: 100px;
  text-align: left;
  width: 140px;
  font-size: 13px;
}
.footwidth{
  width:98%
}

.rts___tab {
  padding: 10px 45px!important;
  white-space: nowrap;
  vertical-align: middle;
  text-align: center;
  margin: 0px 35px;
}
@media screen and (min-width: 1600px) {
   
  .buttonbottom{
    bottom: 0px;
  }
  .rts___tab {
    padding: 10px 75px!important;
    white-space: nowrap;
    vertical-align: middle;
    text-align: center;
    margin: 0px 35px;
  }
  .sidebarheight{
    height:420px
  }
  .container2 {
    /* margin-left: 40%; */
    /* margin-top: 15%; */
    width: 436px;
    /* border-radius: 26px 0px 100px 0; */
    /* box-shadow: 1px 1px 2px #cfcbd1, -1px -1px 2px #fff; */
    background: #303030;
    /* box-shadow: 1px 1px 2px #cfcbd1, -1px -1px 2px #fff; */
    box-sizing: border-box;
    height: 250px;
    padding: 10px;
    position: relative;
    /* margin-top: -12px; */
}
  .mobilefoot{
    display:none;
  }

  .widthcontainer {
        width: 54%;
        position: relative;
        left: 165px;
    
      }
 .fixedsidebar {
        position: fixed;
        right: 240px;
        width: 23%;
        bottom: 50px;
    }
    .btn-wrapper {
      position: relative;
      bottom: 0px;
      text-align: center;
      margin: 0%!important; 
      margin-bottom: 10px;
    }
  .fontsizeslider{
    font-size: 14px;
  }
  .ltn__product-item-4 .product-price {
    font-size: 13px;
    font-family: var(--ltn__heading-font);
    font-weight: 600;
    color: var(--ltn__secondary-color);
    margin-bottom: 5px;
}
.home-banner {
  position: relative;
  background-color: #dcdcdc;
  height: 98vh;
  background-size: cover;
  background-position: center center;
  padding-top: 70px;
}
.imgposition{
  position: relative;
  top: 202px;
}

.ltn__slide-item-2 .slide-item-info {
  max-width: 741px;
}
.ltn__slide-item-2 .slide-title {
  font-size: 40px;
  line-height: 1;
  margin-bottom: 20px;
}
.mobheader{
  display:none;
}
  }

 
  
  .second-navbar {
    background-color: #611818;
    color: #333;
    height: 40px;
    position: fixed;
    top: 60px;
    left: 0;
    width: 100%;
    z-index: 999;
    display: none;
  }
  .btn-wrapper {
    position: relative;
    text-align: center;
    margin: 7%;
}
.bottommargin{
  margin-bottom: -100px;
}
  
  @media only screen and (max-width: 440px) {

  .showmore {
      position: relative;
      right: -117px;
      /* top: -90px; */
      /* border: 1px solid black; */
      width: 17%;
      height: 28px;
      border-radius: 10px;
      font-size: 12px;
      top: -6px;
  }
  .showless {
    position: relative;
    right: -120px;
    bottom: 6px;
    /* border: 1px solid black; */
    width: 17%;
    height: 28px;
    border-radius: 10px;
    font-size: 12px;
    top: -10px;
}

    .btnview {
      position: absolute;
      bottom: 1px;
      width: max-content;
      right: 100px;
  }
    .navbarimage {
      height: 115px;
      /* margin-bottom: 30px; */
  }
  .navbarimage2 {
    height: 270px;
    margin-top: 90px;
}
    p {
      margin-top: 0;
      /* margin-bottom: 1rem; */
      font-family: Times new Roman;
      font-size: 12px;
      font-weight: 300;
      line-height: 12px;
  }
  .btn-wrapper {
    position: relative;
    bottom: -40px;
    text-align: center;
    margin: 7%;
}
  
  
    .footmargin{
      display: flex;
      height: 30px;
      margin-top: 5px;
      margin-bottom: 5px;

    }
    .footer-top-area {
      padding-top: 5px;
      padding-bottom: 5px;
      position: relative;
      bottom: 42px;
  }
  .ltn__copyright-design {
    display:none;
  }
    .divheight {
      height: 100px;
      text-align: left;
      width: 100px;
      font-size: 10px;
    }
    .bottommargin{
      margin-bottom: 180px;
    }
    .right_col {
      max-width: 100%;
      flex: 0 0 50%;
      padding: 40px;
  }
    .col-lg-3{
      padding-right: 15px;
      padding-left: 10%;
      position: relative;
    }
    .notvisible{
      display:none;
    }
    .navbarmobile{
      display:block;
    }
    .fixedsidebar{
      display:none;
    }
    .widthcontainer{
      width: 90%;
      position: relative;
      top: 95px;
    }
    .flex-container {
      display: flex;
      flex-wrap: wrap;
      padding: 5px;
  }
  .box {
    display: flex;
    flex-direction: column;
    width: calc(44.3% - 5px);
    margin: 10px;
    padding: 20px;
    /* box-shadow: -1px 5px 6px #747373; */
    object-fit: contain;
    font-size: 12px;
    align-items: center;
    text-transform: uppercase;
    border-radius: 50px;
    /* background: linear-gradient(145deg, #efecec, #c9c7c7); */
    box-shadow: 28px 28px 56px #bebcbc, -28px -28px 56px #fffefe;
    height: 130px;
    /* margin-right: 45px; */
  }
  .btn-1 {
    animation: shine 3s infinite;
    background: linear-gradient(191deg,#fea,#dc8 49%,#a95 51%,#dc8);
    /* width: 43%; */
     margin-left:1px;
    text-transform: uppercase;
    font-size: 11px;
    height: 39px;
    border-radius: 21px;
    /* margin: 11px; */
    margin-top: 16px;
  }
  .whatsappbutton{
    width: 48%;
    position: relative;
    bottom: 22px;
    font-size: 12px;
    }
    .schedulevisitbutton{
    width: 48%;
    position: relative;
    right: 0px;
    font-size:12px;
  
    }
    .home-banner {
      position: relative;
      /* background-color: #dcdcdc; */
      height: 38vh;
      /* background-size: cover; */
      /* background-position: center center; */
      /* padding-top: 70px; */
  }
  .propertywiseleft {
    width: 200px;
    font-size: 9px;
    font-weight: 700;
    line-height: 40px;
    color:black;
    z-index: 2;
  }
  .propertywise {
      background: #fff;
      border-radius:10px;
      box-shadow: 28px 28px 56px #bebcbc, -28px -28px 56px #fffefe;
      display: flex;
      height: 50px;
      position: relative;
      line-height: 46px;
      margin: 5px;
      padding: 8px;
      text-align: left;
      text-transform: uppercase;
      width: 75%;
      width: 285px;
      align-content: center;
  }
  .heading {
    border-bottom: solid 1px #e1dada;
    padding: 17px;
    font-size: 14px;
    font-weight: 600;
    height: 55px;
    color: black;
    text-align: center;
    opacity: 0.99;
  }
  .iconsize {
    width: 85px;
    padding: 5px;
}
  .propertywise2 {
    
    background: #fff;
    border-radius:10px;
    box-shadow: 28px 28px 56px #bebcbc, -28px -28px 56px #fffefe;
    display: flex;
    height: 50px;
    line-height: 46px;
    margin: 11px;
    padding: 8px;
    text-align: left;
    text-transform: uppercase;
    width: 100%;
    z-index: 2;
  }
  .mobileviewwhide{
    display:none;
  }
  .floorplandescription {
    width: 78%!important;
    display: block;
  }
  .my-div{
    display:none;
  }
  .call-buton .cc-calto-action-ripple {
    z-index: 99999;
    position: fixed;
    right: 2rem;
    bottom: 6rem;
    background: #b48811;
    width: 3rem;
    height: 3rem;
    padding: 1rem;
    border-radius: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #ffffff;
    -webkit-animation: cc-calto-action-ripple 0.6s linear infinite;
    animation: cc-calto-action-ripple 0.6s linear infinite;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-items: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-decoration: none;
}
.left_col {
  display:none
}
  }
  